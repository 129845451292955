"use client";

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInValidationSchema } from "@/utils/form";
import { login, loginWithCookie } from "@/app/actions";
import { toast } from "react-toastify";
import { useRouter } from "next/navigation";
import { Dismiss20Filled } from '@fluentui/react-icons';
import { GoogleLogo, FacebookLogo, Envelope } from '@phosphor-icons/react';
import pb from "@/lib/pocketbase";

function ModalSignIn() {
  const router = useRouter();
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [authProviders, setAuthProviders] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(signInValidationSchema),
  });

  const email = watch("email");

  useEffect(() => {
    const getAuthProviders = async () => {
      try {
        pb.autoCancellation(false)
        const authMethods = await pb.collection('user').listAuthMethods();
        const providers = authMethods.authProviders.map(provider => provider.name);
        setAuthProviders(providers);
      } catch (error) {
        console.error('Failed to get auth providers:', error);
      }
    };
    getAuthProviders();
  }, []);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleGoogleSignIn = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const authData: any = await pb.collection('user').authWithOAuth2({
        provider: 'google'
      });
      
      if (authData) {
        await loginWithCookie(pb.authStore.exportToCookie());
        window.location.href = "/account";
      }
    } catch (error) {
      console.error('OAuth authentication error:', error);
      console.log((error as any)?.originalError);
      toast.error("Failed to authenticate with Google", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleFacebookSignIn = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const authData: any = await pb.collection('user').authWithOAuth2({
        provider: 'facebook'
      });
      
      if (authData) {
        await loginWithCookie(pb.authStore.exportToCookie());
        window.location.href = "/account";
      }
    } catch (error) {
      console.error('OAuth authentication error:', error);
      console.log((error as any)?.originalError);
      toast.error("Failed to authenticate with Facebook", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleContinueWithEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isValidEmail(email)) {
      setShowEmailForm(true);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      console.log('onSubmit')
      const auth = (await login({ email: data.email, password: data.password }))
      if (auth.success) {
        reset();
        document.getElementById("sign-in-modal")?.click();
        router.push("/account");
      } else {
        throw new Error(auth.response.message)
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  return (
    <>
      <input
        type="checkbox"
        id="sign-in-modal"
        className="modal-toggle"
        onClick={() => {
          reset();
          setShowEmailForm(false);
        }}
      />
      <label htmlFor="sign-in-modal" className="modal cursor-pointer">
        <label className="modal-box relative bg-base-100 max-w-full md:max-w-[550px] py-4 px-3 md:p-6">
          <div className="flex justify-end pb-2 select-none">
            <label
              htmlFor="sign-in-modal"
              className="cursor-pointer text-base-content"
            >
              <Dismiss20Filled />
            </label>
          </div>
          <div>
            <div className="w-[100%] bg-gradient-to-r from-primary to-secondary px-6 pt-2 mt-3 pb-6 rounded-lg text-primary-content">
              <h3 className="pb-1 text-3xl font-bold md:text-3xl pt-6">
                Welcome back!
              </h3>
              <p className="text-sm md:text-base">Lets kick some more ass?</p>
            </div>

            {!showEmailForm ? (
              <div className="flex flex-col gap-4 mt-6 px-4">
                {authProviders.includes('google') && (
                  <button 
                    onClick={handleGoogleSignIn}
                    className="btn btn-outline gap-2 w-full"
                  >
                    <GoogleLogo size={24} weight="fill" />
                    Sign in with Google
                  </button>
                )}

                {authProviders.includes('facebook') && (
                  <button
                    onClick={handleFacebookSignIn} 
                    className="btn btn-outline gap-2 w-full"
                  >
                    <FacebookLogo size={24} weight="fill" />
                    Sign in with Facebook
                  </button>
                )}

                {(authProviders.includes('google') || authProviders.includes('facebook')) && (
                  <div className="divider">OR</div>
                )}

                <div className="relative">
                  <input
                    type="email"
                    className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary"
                    placeholder="Enter your email..."
                    {...register("email")}
                  />
                  <Envelope 
                    size={20} 
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-base-content/60"
                  />
                </div>

                <button
                  onClick={handleContinueWithEmail}
                  disabled={!email || !isValidEmail(email)}
                  className="btn btn-primary w-full"
                >
                  Continue with Email
                </button>

                <div className="text-sm text-center mt-4">
                  Don&apos;t have an account?{" "}
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      document.getElementById("sign-in-modal")?.click();
                      document.getElementById("sign-up-modal")?.click();
                    }}
                    className="text-primary hover:text-primary/60 cursor-pointer"
                  >
                    Sign up
                  </span>
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-6">
                <div className="relative">
                  <input
                    type="password"
                    className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary disabled:opacity-50 disabled:pointer-events-none"
                    placeholder="Your password..."
                    aria-label="Your password..."
                    {...register("password")}
                  />
                  <div className="text-start text-sm italic text-error-content">
                    {errors.password?.message}&nbsp;
                  </div>
                </div>

                <div className="flex flex-row w-full justify-between mt-4">
                  <div className="flex flex-row gap-x-4">
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowEmailForm(false);
                      }}
                      className="btn btn-outline"
                    >
                      Back
                    </button>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-primary"
                    >
                      Sign In
                      {isSubmitting && <div className="loading"></div>}
                    </button>
                  </div>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      document.getElementById("password-reset-modal")?.click();
                    }}
                    className="btn btn-ghost text-primary capitalize border-none"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            )}
          </div>
        </label>
      </label>
    </>
  );
}

export default ModalSignIn;

"use client";

import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {  User } from "@/types";
import { Dismiss20Filled } from '@fluentui/react-icons';
import { GoogleLogo, FacebookLogo, Envelope } from '@phosphor-icons/react';
import ContinueWithEmail from "./ContinueWithEmail";
import pb from "@/lib/pocketbase";
import { toast } from "react-toastify";
import { useRouter } from "next/navigation";
import { loginWithCookie } from "@/app/actions";

function ModalSignUp({
  setUser,
}: {
  setUser: Dispatch<SetStateAction<User | undefined>>;
}) {
  const router = useRouter();
  const {
    register,
    reset,
    watch,
  } = useForm();
  
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [authProviders, setAuthProviders] = useState<string[]>([]);
  const email = watch("email");

  useEffect(() => {
    const getAuthProviders = async () => {
      try {
        const authMethods = await pb.collection('user').listAuthMethods();
        const providers = authMethods.authProviders.map(provider => provider.name);
        setAuthProviders(providers);
      } catch (error) {
        console.error('Failed to get auth providers:', error);
      }
    };
    getAuthProviders();
  }, []);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // client side auth
  const handleGoogleSignup = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const authData: any = await pb.collection('user').authWithOAuth2({
        provider: 'google',
        createData: {
          emailVisibility: true,
          role: 'User',
          lastSeen: new Date().toISOString()
        }
      });
      
      if (authData) {
        // Update the user record with name data from OAuth
        await pb.collection('user').update(authData.record.id, {
          displayName: authData.meta.name,
          firstName: authData.meta.rawUser.given_name,
          lastName: authData.meta.rawUser.family_name
        });

        setUser(authData.record as User);
        await loginWithCookie(pb.authStore.exportToCookie());
        window.location.href = "/account";
      }
    } catch (error) {
      console.error('OAuth authentication error:', error);
      console.log((error as any)?.originalError);
      toast.error("Failed to authenticate with Google", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleFacebookSignup = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const authData: any = await pb.collection('user').authWithOAuth2({
        provider: 'facebook',
        createData: {
          emailVisibility: true,
          role: 'User',
          lastSeen: new Date().toISOString()
        }
      });
      
      if (authData) {
        // Update the user record with name data from OAuth
        await pb.collection('user').update(authData.record.id, {
          displayName: authData.meta.name,
          firstName: authData.meta.rawUser.given_name,
          lastName: authData.meta.rawUser.family_name
        });

        setUser(authData.record as User);
        await loginWithCookie(pb.authStore.exportToCookie());
        window.location.href = "/account";
      }
    } catch (error) {
      console.error('OAuth authentication error:', error);
      console.log((error as any)?.originalError);
      toast.error("Failed to authenticate with Facebook", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleContinueWithEmail = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isValidEmail(email)) {
      setShowEmailForm(true);
    }
  };

  return (
    <>
      <input
        type="checkbox"
        id="sign-up-modal"
        className="modal-toggle"
        name=""
        onClick={() => {
          reset();
          setShowEmailForm(false);
        }}
      />
      <label htmlFor="sign-up-modal" className="modal cursor-pointer">
        <label className="modal-box relative max-w-full md:max-w-[550px] py-4 px-3 md:p-6">
          <div className="flex justify-end pb-2 select-none">
            <label
              htmlFor="sign-up-modal"
              className="cursor-pointer text-base-content"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                reset();
                setShowEmailForm(false);
              }}
            >
              <Dismiss20Filled />
            </label>
          </div>
          <div className="flex flex-grow flex-col h-[30rem] lg:h-full overflow-y-scroll">
            <div className="w-[100%] bg-gradient-to-r from-primary to-secondary px-6 mt-3 pb-6 rounded-lg text-primary-content">
              <h3 className="pb-1 text-3xl font-bold md:text-3xl pt-6">
                Want to 10X Your Dev Game?
              </h3>
              <p className="text-sm md:text-base">
                Signup and get started using FastPocket
              </p>
            </div>

            {!showEmailForm ? (
              <div className="flex flex-col gap-4 mt-6 px-4">
                {authProviders.includes('google') && (
                  <button 
                    onClick={handleGoogleSignup}
                    className="btn btn-outline gap-2 w-full"
                  >
                    <GoogleLogo size={24} weight="fill" />
                    Sign up with Google
                  </button>
                )}

                {authProviders.includes('facebook') && (
                  <button
                    onClick={handleFacebookSignup} 
                    className="btn btn-outline gap-2 w-full"
                  >
                    <FacebookLogo size={24} weight="fill" />
                    Sign up with Facebook
                  </button>
                )}

                {(authProviders.includes('google') || authProviders.includes('facebook')) && (
                  <div className="divider">OR</div>
                )}

                <div className="relative">
                  <input
                    type="email"
                    className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary"
                    placeholder="Enter your email..."
                    {...register("email")}
                  />
                  <Envelope 
                    size={20} 
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-base-content/60"
                  />
                </div>

                <button
                  onClick={handleContinueWithEmail}
                  disabled={!email || !isValidEmail(email)}
                  className="btn btn-primary w-full"
                >
                  Continue with Email
                </button>

                <div className="text-sm text-center mt-4">
                  Already have an account?{" "}
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      document.getElementById("sign-up-modal")?.click();
                      document.getElementById("sign-in-modal")?.click();
                    }}
                    className="text-primary hover:text-primary/60 cursor-pointer"
                  >
                    Sign in
                  </span>
                </div>
              </div>
            ) : (
              <ContinueWithEmail 
                email={email}
                setUser={setUser}
                onBack={() => setShowEmailForm(false)}
              />
            )}
          </div>
        </label>
      </label>
    </>
  );
}

export default ModalSignUp;

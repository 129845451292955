"use client";

import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpValidationSchema } from "@/utils/form";
import { companySizeList } from "@/constants";
import pb from "@/lib/pocketbase";
import { createCheckoutSession, login } from "@/app/actions";
import { toast } from "react-toastify";
import { Price, User } from "@/types";
import { useRouter } from "next/navigation";

interface ContinueWithEmailProps {
  email: string;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  onBack: () => void;
}

function ContinueWithEmail({ email, setUser, onBack }: ContinueWithEmailProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(signUpValidationSchema),
    defaultValues: {
      email
    }
  });

  const router = useRouter();

  const generateCheckoutPage = async (price: Price, type: string) => {
    try {
      const checkoutSessionResponse = await createCheckoutSession(
        price.price_id,
        type
      );
      router.push(checkoutSessionResponse.url);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const onSubmit = async (data: any) => {
    data = {
      emailVisibility: false,
      lastSeen: new Date(),
      role: "Admin",
      displayName: `${data.firstName} ${data.lastName}`,
      passwordConfirm: data.password,
      ...data,
    };

    try {
      const organisation = await pb.collection("organisation").create({
        name: data.organisation,
        organisationSize: data.organisationSize,
      });

      const user = await pb
        .collection("user")
        .create({ ...data, organisation: organisation.id });

      const auth = await login({ email: data.email, password: data.password });
      
      if (auth.success) {
        reset();
        document.getElementById("sign-up-modal")?.click();
        const price = localStorage.getItem("price");
        const type = localStorage.getItem("type");
        setUser(user as User);
        
        price
          ? generateCheckoutPage(JSON.parse(price), type ?? "")
          : router.push("/account");
      } else {
        throw new Error(auth.response.message);
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(
          Object.values((error as any).data.data)
            .map((x: any) => x.message)
            .join(),
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    }
  };

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onBack();
  };

  return (
    <div className="flex flex-col gap-4 mt-6 px-4">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="flex flex-row gap-4">
          <div className="relative mt-1 flex-grow">
            <input
              type="text"
              className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary"
              placeholder="First Name…"
              {...register("firstName")}
            />
            <div className="text-start text-sm italic text-error-content">
              {errors.firstName?.message}&nbsp;
            </div>
          </div>
          
          <div className="relative mt-1 flex-grow">
            <input
              type="text"
              className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary"
              placeholder="Last Name…"
              {...register("lastName")}
            />
            <div className="text-start text-sm italic text-error-content">
              {errors.lastName?.message}&nbsp;
            </div>
          </div>
        </div>

        <div className="relative mt-1">
          <input
            type="text"
            className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary"
            placeholder="Phone Number…"
            {...register("phoneNumber")}
          />
          <div className="text-start text-sm italic text-error-content">
            {errors.phoneNumber?.message}&nbsp;
          </div>
        </div>

        <div className="flex flex-row gap-4">
          <div className="relative mt-1 flex-grow">
            <input
              type="text"
              className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary"
              placeholder="Organisation…"
              {...register("organisation")}
            />
            <div className="text-start text-sm italic text-error-content">
              {errors.organisation?.message}&nbsp;
            </div>
          </div>

          <div className="relative mt-1 flex-grow">
            <select
              defaultValue=""
              className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary"
              {...register("organisationSize")}
            >
              <option value="" disabled>Company Size…</option>
              {companySizeList.map((size, i) => (
                <option key={i} value={size}>{size}</option>
              ))}
            </select>
            <div className="text-start text-sm italic text-error-content">
              {errors.organisationSize?.message}&nbsp;
            </div>
          </div>
        </div>

        <div className="relative mt-1">
          <input
            type="password"
            className="py-3 px-4 block w-full bg-base-200 text-base-content border-primary/40 rounded-lg text-sm focus:border-secondary focus:ring-secondary"
            placeholder="Password..."
            {...register("password")}
          />
          <div className="text-start text-sm italic text-error-content">
            {errors.password?.message}&nbsp;
          </div>
        </div>

        <div className="flex flex-row gap-4 mt-4">
          <button
            type="button"
            onClick={handleBack}
            className="btn btn-outline"
          >
            Back
          </button>

          <button
            disabled={isSubmitting}
            type="submit"
            className="btn btn-primary"
          >
            Sign Up
            {isSubmitting && <div className="loading"></div>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContinueWithEmail;
